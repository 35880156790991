<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Staff</h4>
            <div class="small text-muted">Adminsitración de todo el staff que interactua con el software</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col sm="6" md="6" lg="4">
              <Widget :configWidget="configWidget" reference="indicator_total_staff" :hasAccess="configWidget.elements.indicatorTotalStaff" />              
            </b-col>
            <b-col sm="6" md="6" lg="4">
              <Widget :configWidget="configWidget" reference="list_staff_without_access" :hasAccess="configWidget.elements.listStaffWithoutAccess" />                            
            </b-col>         
          </b-row>
        </b-col>  
        <b-col lg="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openStaff()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Staff</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Adminsitración del staff
              </p>
            </b-list-group-item>
          </b-list-group>   

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>                            
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PERSONAL,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.PERSONAL,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalStaff: true,    
            listStaffWithoutAccess: true,                    
          }
        },
        primaryColor: '',
        arr: {
          PHPCustom: []
        }        
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalStaff = Helper.hasAccessWidget(this.configWidget, 'indicator_total_staff')      
      this.configWidget.elements.listStaffWithoutAccess = Helper.hasAccessWidget(this.configWidget, 'list_staff_without_access')            
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openStaff() {
        this.$router.push({ name: 'StaffListStaff' })
      },    

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.PERSONAL)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
